<template>
    <div class="page">
        <navTop title="" :leftIcon="true"></navTop>
         <div class="home-title">{{obj.name}}</div>
        <div v-html="obj.describe"></div>
    </div>
</template>
<script>
import { companyDetail } from "@/api/index";
import navTop from "@/components/common/navTop.vue";
export default {
    components: {
    navTop,
  },
    data(){
        return{
            obj:{},
        }
    },
    created(){
        let id = this.$route.query.id;
        this.getcompanyDetail(id)
    },
    methods:{
 // 区域列表
    async getcompanyDetail(id) {
      try {
        const { code, data } = await companyDetail({ company_id: id });
        this.obj = data;
      } catch (e) {
        console.log(e);
      }
    },
    }
}
</script>
<style scoped>
.home-title {
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700;
  padding-left: 20px;
}
</style>